import Constants from 'constants/index';
import { arrayIntersect } from 'util/keepDuplicatesFromTwoArrays';
import { arrayToCommaString } from 'util/arrayToCommaString';
import { messageReactNative } from 'util/messageReactNative';
import { generateReport, printToNative } from 'util/generateReport';
import { getNameFromLanguage } from 'util/language';
import { mapCategoryToAliasTranslation } from 'util/categoryUtils';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  useGetAssignableChecklistsQuery,
  useGetCourseReportQuery,
  useGetIsCourseEnabledQuery,
  useGetOperatorsQuery,
  useGetTeamMembersQuery,
} from 'services/pathwayApi';
import styled from 'styled-components';
import LoadingOverlay from 'sharedComponents/app/LoadingOverlay';
import { setHeader } from 'store/header/slice';
import {
  selectAllLocationsWithAtLeastLeaderPermissions,
  selectAllLocationsWithAtLeastTrainer,
  selectLocationsWithOperatorPermission,
  selectLocationsWithTrainerPermission,
  selectUserIsStaff,
} from 'store/user/selectors';
import {
  Typography,
  useBreakpoints,
  useMediaQuery,
} from 'cfa-react-components/dist/cjs';
import CheckboxList from 'sharedComponents/app/CheckboxList';
import ReportsCompliancePlanCard from 'components/PlanCard/PlanCards/ReportsCompliancePlanCard';
import PrintReportModal from 'sharedComponents/app/popups/PrintReportModal';
import { withRoles } from 'sharedComponents/app/withRoles';
import { convertDateForReports } from '../../../util/date';

const ReportsCompliancePlansTab = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const locationsWithAtLeastLeaderPermission = useSelector(
    selectAllLocationsWithAtLeastLeaderPermissions,
  );
  const breakpoints = useBreakpoints();
  const isSmAndDown = useMediaQuery(breakpoints.down('sm'));
  const [showChooseReportLocationsPopup, setShowChooseReportLocationsPopup] =
    useState(false);
  const [showDoBChecked, setShowDoBChecked] = useState(false);
  const [noLocationsSelected, setNoLocationsSelected] = useState(false);
  const [isFoodSafety, setIsFoodSafety] = useState(false);
  const [compliancePlans, setCompliancePlans] = useState([]);
  const [planId, setPlanId] = useState('');
  const [triggerComplianceReportHandler, setTriggerComplianceReportHandler] =
    useState(false);
  const [statusReport, setStatusReport] = useState({
    courseId: '',
    courseName: '',
    foodSafetyImage: '',
    locations: [''],
    timeStamp: '',
    userData: [],
  });
  const { data: operators } = useGetOperatorsQuery();
  const locationsWithAtLeastTrainer = useSelector(
    selectAllLocationsWithAtLeastTrainer,
  );
  const locationsWithAtLeastTrainerPermission = useSelector(
    selectLocationsWithTrainerPermission,
  );
  const locationsWithAtLeastOperatorPermission = useSelector(
    selectLocationsWithOperatorPermission,
  );
  const isStaff = useSelector(selectUserIsStaff);
  const isTrainerOrLeaderOrOperator =
    !!locationsWithAtLeastTrainerPermission.length ||
    !!locationsWithAtLeastLeaderPermission.length ||
    !!locationsWithAtLeastOperatorPermission.length;
  const OperatorsOfLocationsWithAtLeastLeader = operators?.filter(
    operator =>
      arrayIntersect(operator?.locations, locationsWithAtLeastLeaderPermission)
        .length > 0,
  );
  const [selectedReportLocations, setSelectedReportLocations] = useState(
    locationsWithAtLeastTrainer,
  );

  const { data: complianceStatusData } = useGetIsCourseEnabledQuery(
    OperatorsOfLocationsWithAtLeastLeader?.[0]?.id ?? operators?.[0]?.id,
    {
      skip: !operators?.length,
    },
  );

  const { data: unOrderedPlans, isFetching } =
    useGetAssignableChecklistsQuery();

  const { data: allTeamMembersData } = useGetTeamMembersQuery(
    {
      locations: locationsWithAtLeastTrainer,
    },
    { refetchOnMountOrArgChange: true },
  );

  const {
    data: statusReportData,
    isFetching: statusReportIsFetching,
    isSuccess: isStatusReportSuccess,
  } = useGetCourseReportQuery(
    {
      courseId: planId,
      location: selectedReportLocations?.length
        ? selectedReportLocations
        : locationsWithAtLeastTrainer,
    },
    {
      skip: !planId || !isTrainerOrLeaderOrOperator,
    },
  );

  useEffect(() => {
    dispatch(setHeader(t('Generic.reports')));
  }, [dispatch, t]);

  // Check if compliance plan is enabled
  useEffect(() => {
    if (isStaff && unOrderedPlans) {
      setCompliancePlans(unOrderedPlans?.courses);
    } else if (complianceStatusData && unOrderedPlans) {
      const compliancePlansWithStatus = unOrderedPlans?.courses
        ?.filter(plan => plan?.courseID)
        .map(course => ({
          ...course,
          enabled: complianceStatusData?.[course?.id]?.enabled ?? false,
        }));

      setCompliancePlans(compliancePlansWithStatus);
    }
  }, [complianceStatusData, isStaff, unOrderedPlans]);

  // Status Report
  useEffect(() => {
    if (isStatusReportSuccess) {
      setStatusReport(statusReportData);
    }
  }, [isStatusReportSuccess, statusReportData]);

  const statusReportToPrint = [...statusReport?.userData]
    .sort((teamMemberA, teamMemberB) => {
      return teamMemberA.firstName.localeCompare(teamMemberB.firstName);
    })
    .map(report => {
      return {
        [Constants.REPORT_TABLE.NAME]: `${report.firstName} ${report.lastName}`,
        [Constants.REPORT_TABLE.DATE_OF_BIRTH]: report.birthDate
          ? report.birthDate
          : t('Generic.na'),
        [Constants.REPORT_TABLE.COMPLETION_DATE]: report.courseCompletionDate
          ? convertDateForReports(report.courseCompletionDate)
          : t('Generic.notInCompliance'),
        [Constants.REPORT_TABLE.EXPIRATION_DATE]: report?.certificate
          ?.expiration
          ? convertDateForReports(report.certificate.expiration)
          : t('Generic.na'),
      };
    });

  const tableHead = [
    [
      Constants.REPORT_TABLE.NAME,
      Constants.REPORT_TABLE.COMPLETION_DATE,
      Constants.REPORT_TABLE.EXPIRATION_DATE,
    ],
  ];
  if (showDoBChecked) {
    tableHead?.[0]?.splice(1, 0, Constants.REPORT_TABLE.DATE_OF_BIRTH);
  }

  //eslint-disable-next-line
  const getReportToGenerate = () => {
    return {
      category: t(
        mapCategoryToAliasTranslation(Constants.PLAN_CATEGORIES.COMPLIANCE),
      ),
      foodSafetyImage: statusReport?.foodSafetyLetters?.[0]?.content,
      location: `${t('Generic.at')} ${
        !selectedReportLocations.length && statusReport?.locations
          ? statusReport?.locations.toString()
          : arrayToCommaString(selectedReportLocations, t('Generic.and'))
      }`,
      name: statusReport?.courseName,
      reportToPrint: statusReportToPrint.map(teamMember =>
        tableHead?.[0]?.map(headerItem => teamMember?.[headerItem]),
      ),
      tableHead,
    };
  };

  //eslint-disable-next-line
  const getMessageToSend = () => {
    const reportToGenerate = getReportToGenerate();
    return {
      category: reportToGenerate.category,
      location: reportToGenerate.location,
      name: reportToGenerate.name,
      reportToPrint: statusReportToPrint,
      tableHead,
    };
  };

  const handleCurrentFilteredLocations = useCallback(() => {
    setSelectedReportLocations(locationsWithAtLeastTrainer);
    if (!!noLocationsSelected) {
      setNoLocationsSelected(false);
    }
    setShowDoBChecked(false);
  }, [locationsWithAtLeastTrainer, noLocationsSelected]);

  const handlePrintReport = useCallback(() => {
    if (
      !selectedReportLocations.length &&
      locationsWithAtLeastTrainer?.length > 1
    ) {
      setNoLocationsSelected(true);
    } else {
      setShowChooseReportLocationsPopup(false);
      generateReport(getReportToGenerate());
      messageReactNative(
        Constants.RN_MESSAGE_TYPES.PRINT,
        printToNative(getMessageToSend()),
      );
      setStatusReport({
        courseId: '',
        courseName: '',
        foodSafetyImage: '',
        locations: [''],
        timeStamp: '',
        userData: [],
      });
      setPlanId('');
      handleCurrentFilteredLocations();
    }
  }, [
    getMessageToSend,
    getReportToGenerate,
    handleCurrentFilteredLocations,
    locationsWithAtLeastTrainer?.length,
    selectedReportLocations.length,
  ]);

  useEffect(() => {
    if (
      !!statusReport.courseId &&
      !!triggerComplianceReportHandler &&
      !!isStatusReportSuccess &&
      !statusReportIsFetching
    ) {
      handlePrintReport();
      setTriggerComplianceReportHandler(false);
    }
  }, [
    handlePrintReport,
    isStatusReportSuccess,
    statusReport.courseId,
    statusReportIsFetching,
    triggerComplianceReportHandler,
  ]);

  const handleOnPrintReport = (id, plan) => {
    setIsFoodSafety(
      getNameFromLanguage(plan?.courseName).includes('Food Safety'),
    );
    setPlanId(id);
    locationsWithAtLeastTrainer?.length > 1 ||
    getNameFromLanguage(plan?.courseName).includes('Food Safety')
      ? setShowChooseReportLocationsPopup(true)
      : setTriggerComplianceReportHandler(true);
  };

  const handleShowDoBCheckbox = () => {
    setShowDoBChecked(!showDoBChecked);
  };

  const onPrintReportCancel = () => {
    setShowChooseReportLocationsPopup(false);
    handleCurrentFilteredLocations();
  };

  return (
    <>
      <SearchHeaderContainer $isSmAndDown={isSmAndDown}>
        <Typography variant="h3">{t('Reports.tabCompliancePlans')}</Typography>
      </SearchHeaderContainer>
      <PlanCardsList $isSmAndDown={isSmAndDown}>
        <Typography fontWeight="bold" variant="body1">{`${
          compliancePlans?.length ?? 0
        } ${t('TrainingPlans.filtering.plans')}`}</Typography>
        <LoadingOverlay isOpen={isFetching} />
        {!!compliancePlans?.length && !isFetching && (
          <ReportsCardContainer>
            {compliancePlans.map(plan => (
              <ReportsCompliancePlanCard
                assignedUsers={plan?.assignedUsers}
                completedUsers={plan?.completedUsers}
                course={{
                  courseID: plan?.courseID,
                  courseName: plan?.courseName,
                  createdDate: plan?.createdDate,
                  enabled: plan?.enabled,
                  id: plan?.id,
                }}
                data-testid="ReportsCompliancePlanCard"
                enrollments={plan?.enrollments}
                key={plan.courseID}
                locations={locationsWithAtLeastLeaderPermission}
                onPrintReport={({ id }) => handleOnPrintReport(id, plan)}
                totalTeamMembers={
                  allTeamMembersData.filter(
                    (value, innerIndex, self) =>
                      innerIndex ===
                      self.findIndex(user => user.adId === value.adId),
                  ).length
                }
              />
            ))}
          </ReportsCardContainer>
        )}
      </PlanCardsList>
      <PrintReportModal
        bodyText={t('TrainingPlans.chooseWhatToInclude')}
        children={
          locationsWithAtLeastTrainer.length > 1
            ? locationsWithAtLeastTrainer.map((id, idx) => (
                <StyledCheckboxList
                  id={id}
                  idx={idx}
                  key={idx}
                  selectedLocations={selectedReportLocations}
                  setSelectedLocations={setSelectedReportLocations}
                />
              ))
            : null
        }
        handleShowDoBCheckbox={handleShowDoBCheckbox}
        headerText={t('Generic.printReport')}
        isFoodSafety={isFoodSafety}
        isOpen={showChooseReportLocationsPopup}
        noLocationsSelected={noLocationsSelected}
        onClose={onPrintReportCancel}
        primaryButtonHandler={handlePrintReport}
        primaryButtonText={t('Button.print')}
        secondaryButtonHandler={onPrintReportCancel}
        secondaryButtonText={t('Button.cancel')}
        selectedReportLocations={selectedReportLocations}
        showDoBChecked={showDoBChecked}
      />
    </>
  );
};

const PlanCardsList = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  max-width: 100%;
  margin: ${({ $isSmAndDown }) => !$isSmAndDown && '0 6em'};
`;

const SearchHeaderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: ${({ $isSmAndDown }) =>
    $isSmAndDown ? 'column-reverse' : 'row'};
  justify-content: space-between;
  align-items: ${({ $isSmAndDown }) =>
    $isSmAndDown ? 'flex-start' : 'center'};
  margin: 16px 0;
`;

const ReportsCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const StyledCheckboxList = styled(CheckboxList)`
  padding: 6px 0;
`;
export default withRoles(
  ReportsCompliancePlansTab,
  [],
  [Constants.USER_RESTRICTIONS.GREAT_BRITAIN_USER],
);
