import Constants from 'constants/index';
import { getNameFromLanguage } from 'util/language';
import { messageReactNative } from 'util/messageReactNative';
import { generateReport, printToNative } from 'util/generateReport';
import SearchFilterHeader from 'components/SearchFilterHeader/SearchFilterHeader';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { setHeader } from 'store/header/slice';
import { useBreakpoints, useMediaQuery } from 'cfa-react-components';
import {
  useGetReportsPlansQuery,
  useGetReportsTeamMembersQuery,
} from 'services/pathwayApi';
import FilterAndSortButton from 'components/FilterAndSortButton/FilterAndSortButton';
import CheckboxFilterSection from 'components/StickyFilterCard/CheckboxFilterSection';
import { selectAllLocationsWithAtLeastTrainer } from 'store/user/selectors';
import {
  selectFilters,
  selectPagination,
  selectSearchFilter,
  selectSort,
  selectSortedAndFilteredAndPaginatedTeamMembers,
} from 'store/teamMembersFilter/selector';
import StickyFilterCard from 'components/StickyFilterCard/StickyFilterCard';
import {
  addTeamMembersLocationFilter,
  clearTeamMembersCheckboxFilters,
  clearTeamMembersSearchFilter,
  loadMoreTeamMembers,
  removeTeamMembersFilter,
  setTeamMembers,
  setTeamMembersSearchFilter,
  setTeamMembersSort,
} from 'store/teamMembersFilter/slice';
import uniqBy from 'lodash/uniqBy';
import SortFilterHeader from 'components/SortFilterHeader/SortFilterHeader';
import LoadingOverlay from 'sharedComponents/app/LoadingOverlay';
import TeamMemberCard from 'containers/TrainingPlans/ManagePlans/TeamMemberCard/TeamMemberCard';
import LoadMorePaginator from 'components/LoadMorePaginator/LoadMorePaginator';
import { NoMessage } from 'containers/TrainingPlans/ManagePlans/ManagePlanView';
import { withRoles } from 'sharedComponents/app/withRoles';
import { convertDateForReports } from '../../../util/date';

const ReportsTeamMembersTab = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const breakpoints = useBreakpoints();
  const isSmAndDown = useMediaQuery(breakpoints.down('sm'));
  const locations = useSelector(selectAllLocationsWithAtLeastTrainer);
  const searchFilter = useSelector(selectSearchFilter);
  const currentFilters = useSelector(selectFilters);
  const sort = useSelector(selectSort);
  const { showing, total } = useSelector(selectPagination);
  const teamMembers = useSelector(
    selectSortedAndFilteredAndPaginatedTeamMembers,
  );
  const [reportData, setReportData] = useState({
    id: '',
    name: '',
    report: [],
  });

  const sortOptions = [
    {
      id: '1',
      translationString: t('Reports.filtering.aToZ'),
      value: Constants.PLANS_SORT_OPTIONS.A2Z,
    },
    {
      id: '2',
      translationString: t('Reports.filtering.zToA'),
      value: Constants.PLANS_SORT_OPTIONS.Z2A,
    },
    {
      id: '3',
      translationString: t('Reports.filtering.startDate'),
      value: Constants.PLANS_SORT_OPTIONS.START_DATE,
    },
  ];

  // Team Members
  const {
    data: allTeamMembersData,
    isFetching,
    isSuccess,
  } = useGetReportsTeamMembersQuery({}, { refetchOnMountOrArgChange: true });

  // Reports (Plans)
  const { data: reportsData, isSuccess: isSuccessReports } =
    useGetReportsPlansQuery(
      {
        userId: reportData.id,
      },
      { skip: !reportData.id, refetchOnMountOrArgChange: true },
    );

  // Clear Team Members Filters
  useEffect(() => {
    dispatch(clearTeamMembersCheckboxFilters());
    dispatch(clearTeamMembersSearchFilter());
  }, [dispatch]);

  // Set Header
  useEffect(() => {
    dispatch(setHeader(t('Generic.reports')));
  }, [dispatch, t]);

  // Set Team Members
  useEffect(() => {
    if (isSuccess) {
      dispatch(
        setTeamMembers({
          teamMembers: uniqBy(
            allTeamMembersData?.users.map(user => {
              return {
                ...user.user,
                plansAssigned: user.plansAssigned,
                plansCompleted: user.plansCompleted,
              };
            }),
            'adId',
          ),
        }),
      );
    }
  }, [allTeamMembersData, dispatch, isSuccess]);

  // Print Report
  useEffect(() => {
    let plans = [];
    let courses = [];

    // Non-Compliance Plans
    if (isSuccessReports && reportsData.checklistResults) {
      plans = reportsData.checklistResults;
    }
    // Compliance Plans
    if (isSuccessReports && reportsData.coursesResults?.[0]?.enrollments) {
      courses =
        reportsData?.coursesResults?.[0]?.enrollments &&
        Object.keys(reportsData?.coursesResults?.[0]?.enrollments).map(
          key => reportsData?.coursesResults?.[0]?.enrollments?.[key],
        );
    }

    const combinedPlans = plans.concat(courses);
    onPrintReport(combinedPlans);
    setReportData({ ...reportData, report: combinedPlans });
    //eslint-disable-next-line
  }, [dispatch, reportsData]);

  const getTimeString = time => {
    const timeSpentHrs = Math.floor(time / 60);
    const timeSpentMins = time % 60;
    return `${
      timeSpentHrs > 0 ? timeSpentHrs + ' ' + t('Generic.hour') : ''
    } ${timeSpentMins} ${t('Generic.mins')}`;
  };

  const onPrintReport = plans => {
    const statusReportToPrint = plans
      .filter(report =>
        currentFilters?.length
          ? report?.checklist?.locations?.some(location =>
              currentFilters.includes(location),
            )
          : report,
      )
      .sort((planA, planB) => {
        return planA?.checklist
          ? getNameFromLanguage(planA?.checklist?.name).localeCompare(
              getNameFromLanguage(planB?.checklist?.name),
            )
          : getNameFromLanguage(planA?.courseName).localeCompare(
              getNameFromLanguage(planB?.courseName),
            );
      })
      .map(plan => {
        return {
          [Constants.REPORT_TABLE.PLAN]: plan.checklist
            ? getNameFromLanguage(plan.checklist.name)
            : getNameFromLanguage(plan.courseName),
          [Constants.REPORT_TABLE.ITEMS_COMPLETED]: plan.checklist
            ? `${plan.status[0].stepsComplete}/${plan.checklist.stepsTotal}`
            : `${plan.percentComplete}%`,
          [Constants.REPORT_TABLE.TOTAL_TIME_SPENT_ON_PLAN]: plan.courseName
            ? getTimeString(Math.ceil(plan?.duration / 60))
            : plan.checklist
            ? `${getTimeString(
                plan.status[0]?.steps?.reduce(
                  (acc, step) =>
                    acc + Math.round((step?.stepDuration ?? 0) / 60),
                  0,
                ) ?? 0,
              )}`
            : t('Generic.na'),
          [Constants.REPORT_TABLE.STATUS]: plan.checklist
            ? t(
                `TrainingPlans.statusOptions.${
                  Constants.TRAINING_PLANS_STATUSES[plan.status[0].status]
                }`,
              )
            : t(
                `TrainingPlans.statusOptions.${
                  Constants.LEARN_UPON_TRAINING_PLAN_STATUSES[plan.status]
                }`,
              ),
          [Constants.REPORT_TABLE.COMPLETION_DATE]:
            plan.checklist && plan.status[0].completionDate
              ? convertDateForReports(plan.status[0].completionDate)
              : getNameFromLanguage(plan?.courseName)?.includes(
                  'Food Safety',
                ) && plan.status === Constants.LEARN_UPON_TRAINING_PLANS.PASSED
              ? convertDateForReports(plan.completedDate)
              : plan.courseName &&
                plan.completedDate &&
                !getNameFromLanguage(plan?.courseName)?.includes('Food Safety')
              ? convertDateForReports(plan.completedDate)
              : t('Generic.na'),
          [Constants.REPORT_TABLE.EXPIRATION_DATE]: plan?.certificate
            ?.expiration
            ? convertDateForReports(plan?.certificate?.expiration)
            : t('Generic.na'),
        };
      });

    const complianceReport = statusReportToPrint
      .filter(teamMember =>
        teamMember[Constants.REPORT_TABLE.ITEMS_COMPLETED].includes('%'),
      )
      .map(user => {
        return {
          ...user,
          [Constants.REPORT_TABLE.COMPLIANCE_PLAN_NAME]:
            user[Constants.REPORT_TABLE.PLAN],
          [Constants.REPORT_TABLE.EXPIRATION_DATE]:
            user[Constants.REPORT_TABLE.EXPIRATION_DATE],
        };
      });

    const storeReport = statusReportToPrint.filter(
      teamMember =>
        !teamMember[Constants.REPORT_TABLE.ITEMS_COMPLETED].includes('%'),
    );

    const reportToGenerate = {
      category: t('Generic.teamMember'),
      isTeamMember: true,
      name: reportData.name,
      complianceReport: complianceReport.map(teamMember => [
        teamMember['Compliance Plan Name'],
        teamMember[Constants.REPORT_TABLE.TOTAL_TIME_SPENT_ON_PLAN],
        teamMember[Constants.REPORT_TABLE.COMPLETION_DATE],
        teamMember[Constants.REPORT_TABLE.EXPIRATION_DATE],
      ]),
      complianceTableHead: Constants.COMPLIANCE_TABLE_HEAD,
      reportToPrint: storeReport.map(teamMember => [
        teamMember[Constants.REPORT_TABLE.PLAN],
        teamMember[Constants.REPORT_TABLE.ITEMS_COMPLETED],
        teamMember[Constants.REPORT_TABLE.TOTAL_TIME_SPENT_ON_PLAN],
        teamMember[Constants.REPORT_TABLE.STATUS],
        teamMember[Constants.REPORT_TABLE.COMPLETION_DATE],
      ]),
      tableHead: Constants.TABLE_HEAD,
    };

    const messageToSend = {
      category: reportToGenerate.category,
      name: reportToGenerate.name,
      reportToPrint: statusReportToPrint,
      tableHead: Constants.TABLE_HEAD,
    };

    /**
     * There is a minor timing gap when a user clicks the printer icon to
     * when the report loads because we are first setting the ID of the
     * report to print, waiting on the query to get the data, then calling
     * the print function aferward.  We wait for the success status to be
     * returned so that we don't show empty data
     */
    if (isSuccessReports) {
      generateReport(reportToGenerate);
      messageReactNative(
        Constants.RN_MESSAGE_TYPES.PRINT,
        printToNative(messageToSend),
      );
    }
  };

  return (
    <>
      <StyledContent>
        <SearchFilterHeader
          onChange={e =>
            dispatch(
              setTeamMembersSearchFilter({ searchFilter: e.target.value }),
            )
          }
          onClear={() => dispatch(clearTeamMembersSearchFilter())}
          searchPlaceholder={t(
            'TrainingPlans.teamMembers.searchbarPlaceholder',
          )}
          searchValue={searchFilter}
          title={t('Reports.tabTeamMembers')}
        />
        {!!isSmAndDown && !isFetching && (
          <FilterAndSortButton
            onSortChange={option => {
              dispatch(setTeamMembersSort({ sort: option.value }));
            }}
            sortOptions={sortOptions}
            sortValue={sortOptions?.find(option => option.value === sort)}
            text={`${t('TrainingPlans.filtering.show')} ${
              teamMembers?.length ?? 0
            } ${t('TrainingPlans.filtering.results')}`}
          >
            {locations && locations?.length > 1 && (
              <CheckboxFilterSection
                labels={locations.reduce(
                  (acc, loc) => ({
                    ...acc,
                    [loc]: {
                      translationString: loc,
                      value: !!currentFilters.includes(loc),
                    },
                  }),
                  {},
                )}
                onChange={value => {
                  if (!!currentFilters.includes(value)) {
                    dispatch(removeTeamMembersFilter({ filter: value }));
                  } else {
                    dispatch(addTeamMembersLocationFilter({ filter: value }));
                  }
                }}
                title={t('Generic.locations')}
              />
            )}
          </FilterAndSortButton>
        )}
        <TeamMembersList>
          {!isSmAndDown &&
            locations &&
            locations?.length > 1 &&
            !isFetching && (
              <StickyFilterCard>
                <CheckboxFilterSection
                  labels={locations.reduce(
                    (acc, loc) => ({
                      ...acc,
                      [loc]: {
                        translationString: loc,
                        value: !!currentFilters.includes(loc),
                      },
                    }),
                    {},
                  )}
                  onChange={value => {
                    if (!!currentFilters.includes(value)) {
                      dispatch(removeTeamMembersFilter({ filter: value }));
                    } else {
                      dispatch(addTeamMembersLocationFilter({ filter: value }));
                    }
                  }}
                  title={t('Generic.locations')}
                />
              </StickyFilterCard>
            )}
          <TeamMembersContainer>
            <SortFilterHeader
              label={t('TrainingPlans.filtering.sortBy')}
              onChange={option => {
                dispatch(setTeamMembersSort({ sort: option.value }));
              }}
              onClear={() => {
                dispatch(clearTeamMembersCheckboxFilters());
              }}
              options={sortOptions}
              showClear={false}
              text={`${total ?? 0} ${t('Reports.tabTeamMembers')}`}
              value={sortOptions?.find(option => option.value === sort)}
            />
            <LoadingOverlay isOpen={isFetching} />
            <TeamMembersCardsList>
              {!!teamMembers?.length && !isFetching && (
                <>
                  {teamMembers.map((teamMember, idx) => (
                    <TeamMemberCard
                      completedStepCount={teamMember.plansCompleted}
                      id={teamMember.adId ?? ''}
                      isComplete={
                        teamMember.plansCompleted !== 0 &&
                        teamMember.plansCompleted === teamMember.plansAssigned
                      }
                      isReports={true}
                      key={idx}
                      name={teamMember.name}
                      onPrintReport={(id, name) => {
                        reportData?.id === id
                          ? onPrintReport(reportData?.report)
                          : setReportData({ ...reportData, id, name });
                      }}
                      startDate={teamMember.startDate}
                      stepCount={teamMember.plansAssigned}
                    />
                  ))}
                </>
              )}
              {(!!currentFilters?.length || !!searchFilter) && !total && (
                <NoMessage message={t('TrainingPlans.noTeamMembersResults')} />
              )}
            </TeamMembersCardsList>
            <LoadMorePaginator
              onClick={() => dispatch(loadMoreTeamMembers())}
              showing={showing}
              showingText={t('TrainingPlans.showingXOfYTeamMembers', {
                showing,
                total: total ?? 0,
              })}
              total={total ?? 0}
            />
          </TeamMembersContainer>
        </TeamMembersList>
      </StyledContent>
    </>
  );
};

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  max-width: 100%;
`;

const TeamMembersList = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  position: relative;
  flex-grow: 1;
  max-width: 100%;
`;

const TeamMembersContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  overflow: hidden;
`;

const TeamMembersCardsList = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  max-width: 100%;
`;

export default withRoles(
  ReportsTeamMembersTab,
  [],
  [Constants.USER_RESTRICTIONS.GREAT_BRITAIN_USER],
);
