import Constants from 'constants/index';
import { isApiError } from 'util/request';
import { getNameFromLanguage } from 'util/language';
import { Trans, useTranslation } from 'react-i18next';
import LoadingOverlay from 'sharedComponents/app/LoadingOverlay';
import Bugsnag from '@bugsnag/browser';
import { formatBugsnagErrorMessage } from 'bugsnag';
import {
  useAddTrainingPlanMutation,
  useAssignUserToChecklistMutation,
  useDeleteTrainingPlanMutation,
  useDuplicateTemplateTrainingPlanMutation,
  useDuplicateTrainingPlanMutation,
  useGetEditableChecklistsQuery,
  useGetOperatorsQuery,
  useGetTemplatesQuery,
  useUpdateTrainingPlanMutation,
} from 'services/pathwayApi';
import GenericError from 'sharedComponents/app/GenericError';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import {
  selectAllLocationsWithAtLeastLeaderPermissions,
  selectAllLocationsWithAtLeastTrainer,
  selectUserLanguage,
  selectUserId,
  selectLocationsWithAdminPermission,
} from 'store/user/selectors';
import { setHeader } from 'store/header/slice';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import AddPlanWithOperators from 'sharedComponents/app/popups/AddPlanWithOperators';
import PropTypes from 'prop-types';
import ScrollToTop from 'ScrollToTop';
import { withRoles } from 'sharedComponents/app/withRoles';
import { Fab } from 'sharedComponents/app/Fab/Fab';
import CheckboxFilterSection from 'components/StickyFilterCard/CheckboxFilterSection';
import StickyFilterCard from 'components/StickyFilterCard/StickyFilterCard';
import SearchFilterHeader from 'components/SearchFilterHeader/SearchFilterHeader';
import SortFilterHeader from 'components/SortFilterHeader/SortFilterHeader';
import FilterAndSortButton from 'components/FilterAndSortButton/FilterAndSortButton';
import { useBreakpoints, useMediaQuery } from 'cfa-react-components';
import ClearFiltersHeader from 'components/ClearFiltersHeader/ClearFiltersHeader';
import {
  setBuildPlans,
  addBuildPlansCategoryFilter,
  setBuildPlansSearchFilter,
  setBuildPlansSort,
  clearBuildPlansCheckboxFilters,
  clearBuildPlansSearchFilter,
  removeBuildPlansFilter,
  loadMorePlans,
} from 'store/buildPlansFilter/slice';
import {
  selectFilters,
  selectSearchFilter,
  selectSort,
  selectSortedAndFilteredAndPaginatedPlans,
  selectPagination,
} from 'store/buildPlansFilter/selector';
import { IconNotes, IconBooks } from '@tabler/icons-react';
import LoadMorePaginator from 'components/LoadMorePaginator/LoadMorePaginator';
import ToastMessageBlock from 'sharedComponents/app/Toasts/SuccessToast';
import toast from 'react-hot-toast';
import ConfirmationModal from 'sharedComponents/app/popups/ConfirmationModal';
import uniqBy from 'lodash/uniqBy';
import BuildPlansPlanCard from 'components/PlanCard/PlanCards/BuildPlansPlanCard';
import { useAmplitudeTrack } from 'amplitude/useAmplitude';
import TemplateModal from '../CopyTemplateModal/TemplateModal';
import PreviewTemplateModal from '../CopyTemplateModal/PreviewTemplateModal';
import CopyTemplatePopup from '../CopyTemplateModal/CopyTemplatePopup';
import { NoMessage } from '../ManagePlans/ManagePlanView';
import { selectUserPermissions } from '../../../store/user/selectors';

const BuildPlansTab = () => {
  const [addTrainingPlan] = useAddTrainingPlanMutation();
  const [assignUserToChecklist] = useAssignUserToChecklistMutation();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const userLanguage = useSelector(selectUserLanguage);
  const breakpoints = useBreakpoints();
  const isSmAndDown = useMediaQuery(breakpoints.down('sm'));
  const [showAddPlanPopUp, setShowAddPlanPopUp] = useState(false);
  const [showCustomPlan, setShowCustomPlan] = useState(false);
  const [showTemplateModal, setShowTemplateModal] = useState(false);
  const [showCopyTemplatePopup, setShowCopyTemplatePopup] = useState(false);
  const [showInvalidNameError, setShowInvalidNameError] = useState(false);
  const [copiedTemplateId, setCopiedTemplatePlanId] = useState();
  const [previewTemplate, setPreviewTemplate] = useState({});
  const [showPreview, setShowPreview] = useState(false);
  const [deleteTrainingPlan] = useDeleteTrainingPlanMutation();
  const [duplicateTrainingPlan] = useDuplicateTrainingPlanMutation();
  const [duplicateTemplateTrainingPlan] =
    useDuplicateTemplateTrainingPlanMutation();
  const [updateTrainingPlan] = useUpdateTrainingPlanMutation();
  const [isOpen, setIsOpen] = useState(false);
  const track = useAmplitudeTrack();
  const filteredAndSortedPlans = useSelector(
    selectSortedAndFilteredAndPaginatedPlans,
  );
  const userId = useSelector(selectUserId);
  const { showing, total } = useSelector(selectPagination);
  const searchFilter = useSelector(selectSearchFilter);
  const sort = useSelector(selectSort);
  const currentFilters = useSelector(selectFilters);
  const userPermissions = useSelector(selectUserPermissions);

  const locationsWithAtLeastTrainer = useSelector(
    selectAllLocationsWithAtLeastTrainer,
  );
  const locationsWithAtLeastLeader = useSelector(
    selectAllLocationsWithAtLeastLeaderPermissions,
  );

  const locationsWithAtLeastAdmin = useSelector(
    selectLocationsWithAdminPermission,
  );

  const userIsStakeholder = userPermissions.hasOwnProperty(
    Constants.USER_PERMISSIONS.STAKEHOLDER,
  );

  const {
    data: unOrderedPlans,
    isFetching,
    error,
    refetch,
  } = useGetEditableChecklistsQuery();

  const {
    data: operators,
    isFetching: isFetchingOperators,
    error: errorGettingOperators,
  } = useGetOperatorsQuery();

  const operatorsWhereUserIsLeader = operators?.filter(operator =>
    operator?.locations?.some(operatorLocation =>
      locationsWithAtLeastLeader.includes(operatorLocation),
    ),
  );

  const { data: templates, error: templatesError } = useGetTemplatesQuery({
    locations: locationsWithAtLeastTrainer,
  });

  const isValidPlanName = (planName, assignedOperator) => {
    const noAssignedOperatorAndUserIsNotStakeholder =
      !assignedOperator && !userIsStakeholder;

    if (!planName || noAssignedOperatorAndUserIsNotStakeholder) {
      setShowInvalidNameError(true);
      console.error('invalid name or missing operator');
      Bugsnag.notify(
        new Error(
          `Build plans error - no plan name or no operator: ${JSON.stringify({
            planName,
            assignedOperator,
          })}`,
        ),
      );
      return false;
    }
    return true;
  };

  useEffect(() => {
    dispatch(setHeader(t('TrainingPlans.tabPlans')));
  }, [dispatch, t]);

  useEffect(() => {
    dispatch(setBuildPlans({ plans: unOrderedPlans ?? [] }));
  }, [dispatch, unOrderedPlans]);

  // We trigger a refetch when an action is performed from the three-dot menu in TrainingPlan.js
  useEffect(() => {
    refetch();
  }, [refetch]);

  // We trigger the toast notification when a plan is deleted in the three-dot menu in TrainingPlan.js
  useEffect(() => {
    if (location.state && location.state.deletedPlan) {
      setIsOpen(true);
      toast.custom(toastObj => (
        <ToastMessageBlock id={toastObj.id}>
          {`${location.state.deletedPlan} ${t(
            'TrainingPlans.toastMessage.deleted',
          )}`}
        </ToastMessageBlock>
      ));

      // After we display the toast, we set the state back to an empty string
      history.replace({
        pathname: `/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.BUILD_PATH_NAME}`,
        state: { deletedPlan: '' },
      });
    }
  }, [history, location, t]);

  // We trigger the toast notification when a plan is duplicated in the three-dot menu in TrainingPlan.js
  useEffect(() => {
    if (location.state && location.state.duplicatedPlan) {
      setIsOpen(true);
      toast.custom(toastObj => (
        <ToastMessageBlock id={toastObj.id}>
          {`${location.state.duplicatedPlan} ${t(
            'TrainingPlans.toastMessage.duplicated',
          )}`}
        </ToastMessageBlock>
      ));

      // After we display the toast, we set the state back to an empty string
      history.replace({
        pathname: `/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.BUILD_PATH_NAME}`,
        state: { duplicatedPlan: '' },
      });
    }
  }, [history, location, t]);

  if (isApiError(error)) {
    Constants.BUGSNAG_ENABLED &&
      Bugsnag.notify(formatBugsnagErrorMessage(error));
    return <GenericError />;
  }

  if (isApiError(templatesError)) {
    Constants.BUGSNAG_ENABLED &&
      Bugsnag.notify(formatBugsnagErrorMessage(templatesError));
    return <GenericError />;
  }

  if (isApiError(errorGettingOperators)) {
    Constants.BUGSNAG_ENABLED &&
      Bugsnag.notify(formatBugsnagErrorMessage(errorGettingOperators));
    return <GenericError />;
  }

  const onShowCopyPlan = id => {
    setCopiedTemplatePlanId(id);
    setShowPreview(false);
    setShowTemplateModal(false);
    setShowCopyTemplatePopup(true);
  };

  const onCopyPlan = ({ name, assignedOperator }) => {
    const trimmedName = name?.trim();
    let ownerId = assignedOperator?.id;
    if (!ownerId && locationsWithAtLeastAdmin.length > 0) {
      console.warn(
        'No operator id found for users locations, using current user Id',
      );
      ownerId = userId;
    }
    if (!isValidPlanName(trimmedName, assignedOperator)) {
      return;
    }
    duplicateTemplateTrainingPlan({
      ownerId: ownerId,
      checklistId: copiedTemplateId,
      name: {
        en: name,
        es: name,
      },
    })
      .unwrap()
      .then(({ id }) => {
        // fire amplitude event
        if (track !== null) {
          track('training_template_copied', {
            template_id: copiedTemplateId ?? 'empty',
            operator: assignedOperator ?? 'empty',
          });
        }
        refetch();
        history.push(
          `/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.PLAN_PATH_NAME}/${id}`,
        );
      })
      .catch(err => {
        Bugsnag.notify(formatBugsnagErrorMessage(err));
      });
  };

  const onAddPlan = (name, operator) => {
    let ownerId = operator.id;
    if (!ownerId && locationsWithAtLeastAdmin.length > 0) {
      console.warn(
        'No operator id found for users locations, using current user Id',
      );
      ownerId = userId;
    }
    const trimmedName = name?.trim();
    if (!isValidPlanName(trimmedName, ownerId)) {
      return;
    }
    addTrainingPlan({
      // If a user's only permission is stakeholder, we use the user id otherwise we use the owner id
      ownerId: userIsStakeholder ? ownerId ?? userId : ownerId,
      name: {
        en: trimmedName,
        es: trimmedName,
      },
    })
      .unwrap()
      .then(({ id }) => {
        // fire amplitude event
        if (track !== null) {
          track('training_plan_created', {
            training_plan_id: id ?? 'empty',
            owner: ownerId ?? 'empty',
          });
        }
        refetch();
        history.push(
          `/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.PLAN_PATH_NAME}/${id}`,
        );
      })
      .catch(err => {
        Bugsnag.notify(formatBugsnagErrorMessage(err));
      });
  };

  const onDeletePlan = (planId, planName) => {
    deleteTrainingPlan(planId)
      .unwrap()
      .then(() => {
        refetch();
        toast.custom(toastObj => (
          <ToastMessageBlock id={toastObj.id}>
            {`${planName} ${t('TrainingPlans.toastMessage.deleted')}`}
          </ToastMessageBlock>
        ));
        setIsOpen(true);
      })
      .catch(err => {
        Bugsnag.notify(formatBugsnagErrorMessage(err));
      });
  };

  onDeletePlan.propTypes = {
    planId: PropTypes.string.isRequired,
    planName: PropTypes.string.isRequired,
  };

  const onDuplicatePlan = (planId, operatorId, name) => {
    const trimmedName = name?.trim();

    if (!isValidPlanName(trimmedName, operatorId)) {
      return;
    }

    duplicateTrainingPlan({
      ownerId: operatorId,
      checklistId: planId,
      name: {
        en: trimmedName,
        es: trimmedName,
      },
    })
      .unwrap()
      .then(() => {
        refetch();
        toast.custom(toastObj => (
          <ToastMessageBlock id={toastObj.id}>
            {`${trimmedName} ${t('TrainingPlans.toastMessage.duplicated')}`}
          </ToastMessageBlock>
        ));
        setIsOpen(true);
      })
      .catch(err => {
        Bugsnag.notify(formatBugsnagErrorMessage(err));
      });
  };

  const onRenamePlan = (newName, plan) => {
    const trimmedName = newName?.trim();
    if (!isValidPlanName(trimmedName, plan.ownerId)) {
      return;
    }
    const payload = {
      operatorId: plan.operatorId,
      checklist: {
        ...plan,
        name: {
          ...plan.name,
          [userLanguage]: trimmedName,
        },
      },
    };
    updateTrainingPlan(payload)
      .unwrap()
      .then(() => {
        refetch();
        toast.custom(toastObj => (
          <ToastMessageBlock id={toastObj.id}>
            {`${trimmedName} ${t('TrainingPlans.toastMessage.renamed')}`}
          </ToastMessageBlock>
        ));
        setIsOpen(true);
      })
      .catch(err => {
        Bugsnag.notify(formatBugsnagErrorMessage(err));
      });
  };

  const onSaveTranslations = updatedTranslations => {
    const payload = {
      locations: updatedTranslations?.locations,
      checklist: {
        ...updatedTranslations,
      },
    };

    updateTrainingPlan(payload)
      .unwrap()
      .then(() => {
        toast.custom(toastObj => (
          <ToastMessageBlock id={toastObj.id}>
            {`${getNameFromLanguage(updatedTranslations?.name)} ${t(
              'TrainingPlans.translationToastText',
            )} ${
              userLanguage === Constants.LANGUAGE.ENGLISH_LANGUAGE_CODE
                ? Constants.LANGUAGE_OPTIONS.SPANISH
                : Constants.LANGUAGE_OPTIONS.INGLES
            }`}
          </ToastMessageBlock>
        ));
        refetch();
      })
      .catch(err => {
        Bugsnag.notify(formatBugsnagErrorMessage(err));
      });
  };

  const onAssignTeamMembers = (plan, members, dueDate) => {
    const payload = Array.isArray(members)
      ? { userIds: uniqBy(members), dueDate }
      : { userIds: [members], dueDate };

    const numberOfTeamMembersToAssign = payload.userIds.length;

    assignUserToChecklist({ body: payload, id: plan.id })
      .unwrap()
      .then(() => {
        history.push({
          pathname: `/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${plan?.id}/${Constants.ROUTE_PATH_NAMES.TEAM_MEMBERS_PATH_NAME}`,
          state: { refetch: true },
        });
        toast.custom(toastObj => (
          <ToastMessageBlock id={toastObj.id}>
            {numberOfTeamMembersToAssign === 1
              ? t('TrainingPlans.toastMessage.assignedTeamMember')
              : t('TrainingPlans.toastMessage.assignedTeamMembers', {
                  count: numberOfTeamMembersToAssign,
                })}
          </ToastMessageBlock>
        ));
      })
      .catch(err => {
        Bugsnag.notify(formatBugsnagErrorMessage(err));
      });
  };

  const sortOptions = [
    {
      id: '1',
      translationString: t('TrainingPlans.filtering.newest'),
      value: Constants.PLANS_SORT_OPTIONS.NEWEST,
    },
    {
      id: '2',
      translationString: t('TrainingPlans.filtering.oldest'),
      value: Constants.PLANS_SORT_OPTIONS.OLDEST,
    },
    {
      id: '3',
      translationString: t('TrainingPlans.filtering.aToZ'),
      value: Constants.PLANS_SORT_OPTIONS.A2Z,
    },
    {
      id: '4',
      translationString: t('TrainingPlans.filtering.zToA'),
      value: Constants.PLANS_SORT_OPTIONS.Z2A,
    },
  ];

  const categoryLabels = {
    [Constants.PLAN_CATEGORIES.DEFAULT]: {
      translationString: t('TrainingPlans.planCategories.default'),
      value: !!currentFilters.includes(Constants.PLAN_CATEGORIES.DEFAULT),
    },
    [Constants.PLAN_CATEGORIES.BACK_OF_HOUSE]: {
      translationString: t('TrainingPlans.planCategories.backOfHouse'),
      value: !!currentFilters.includes(Constants.PLAN_CATEGORIES.BACK_OF_HOUSE),
    },
    [Constants.PLAN_CATEGORIES.FRONT_OF_HOUSE]: {
      translationString: t('TrainingPlans.planCategories.frontOfHouse'),
      value: !!currentFilters.includes(
        Constants.PLAN_CATEGORIES.FRONT_OF_HOUSE,
      ),
    },
    [Constants.PLAN_CATEGORIES.HOSPITALITY]: {
      translationString: t('TrainingPlans.planCategories.hospitality'),
      value: !!currentFilters.includes(Constants.PLAN_CATEGORIES.HOSPITALITY),
    },
    [Constants.PLAN_CATEGORIES.LEADERSHIP]: {
      translationString: t('TrainingPlans.planCategories.leadership'),
      value: !!currentFilters.includes(Constants.PLAN_CATEGORIES.LEADERSHIP),
    },
    [Constants.PLAN_CATEGORIES.ONBOARDING]: {
      translationString: t('TrainingPlans.planCategories.onboarding'),
      value: !!currentFilters.includes(Constants.PLAN_CATEGORIES.ONBOARDING),
    },
  };

  const hideAddPlanPopUp = () => {
    setShowAddPlanPopUp(false);
  };

  const handlePreviewPlan = template => {
    setPreviewTemplate(template);
    setShowTemplateModal(false);
    setShowPreview(true);
  };

  const handleTemplateBackButton = () => {
    setPreviewTemplate({});
    setShowTemplateModal(true);
    setShowPreview(false);
  };

  const onShowCustomPlanPopup = () => {
    setShowCustomPlan(true);
    setShowAddPlanPopUp(false);
  };

  const onShowTemplateModal = () => {
    setShowTemplateModal(true);
    setShowAddPlanPopUp(false);
  };

  return (
    <>
      {isOpen && <ScrollToTop />}
      <StyledContent>
        <SearchFilterHeader
          onChange={e =>
            dispatch(
              setBuildPlansSearchFilter({ searchFilter: e.target.value }),
            )
          }
          onClear={() => dispatch(clearBuildPlansSearchFilter())}
          searchPlaceholder={t('TrainingPlans.filtering.searchBuildPlans')}
          searchValue={searchFilter}
          title={t('TrainingPlans.tabBuild')}
        />
        {!!isSmAndDown && !isFetching && !isFetchingOperators && (
          <FilterAndSortButton
            onSortChange={option => {
              dispatch(setBuildPlansSort({ sort: option.value }));
            }}
            sortOptions={sortOptions}
            sortValue={sortOptions?.find(option => option.value === sort)}
            text={`${t('TrainingPlans.filtering.show')} ${
              filteredAndSortedPlans?.length ?? 0
            } ${t('TrainingPlans.filtering.results')}`}
          >
            <CheckboxFilterSection
              labels={categoryLabels}
              onChange={value => {
                if (!!currentFilters.includes(value)) {
                  dispatch(removeBuildPlansFilter({ filter: value }));
                } else {
                  dispatch(addBuildPlansCategoryFilter({ filter: value }));
                }
              }}
              title={t('Browse.categories')}
            />
          </FilterAndSortButton>
        )}
        <PlanCardList>
          {!isSmAndDown && !isFetching && !isFetchingOperators && (
            <StickyFilterCard>
              <CheckboxFilterSection
                labels={categoryLabels}
                onChange={value => {
                  if (!!currentFilters.includes(value)) {
                    dispatch(removeBuildPlansFilter({ filter: value }));
                  } else {
                    dispatch(addBuildPlansCategoryFilter({ filter: value }));
                  }
                }}
                title={t('Browse.categories')}
              />
            </StickyFilterCard>
          )}
          <PlanCardsContainer>
            <SortFilterHeader
              label={t('TrainingPlans.filtering.sortBy')}
              onChange={option => {
                dispatch(setBuildPlansSort({ sort: option.value }));
              }}
              onClear={() => {
                dispatch(clearBuildPlansCheckboxFilters());
              }}
              options={sortOptions}
              showClear={false}
              text={`${total ?? 0} ${t('TrainingPlans.filtering.plans')}`}
              value={sortOptions?.find(option => option.value === sort)}
            />
            <ClearFiltersHeader
              aliases={categoryLabels}
              clearAllFilters={() => {
                dispatch(clearBuildPlansCheckboxFilters());
              }}
              clearFilter={value => {
                dispatch(removeBuildPlansFilter({ filter: value }));
              }}
              filters={currentFilters}
            />
            <LoadingOverlay isOpen={isFetching || isFetchingOperators} />
            <PlanCardsList>
              {!filteredAndSortedPlans?.length &&
                !isFetching &&
                !isFetchingOperators &&
                !currentFilters?.length &&
                !searchFilter && (
                  <NoMessage
                    message={<Trans i18nKey={'TrainingPlans.createPlan'} />}
                  />
                )}
              {(!!currentFilters?.length || !!searchFilter) && !total && (
                <NoMessage message={t('TrainingPlans.noPlansResults')} />
              )}
              {!isFetchingOperators && (
                <Fab
                  hasMenu={true}
                  isOpen={showAddPlanPopUp}
                  onClick={() => setShowAddPlanPopUp(!showAddPlanPopUp)}
                  onClose={hideAddPlanPopUp}
                >
                  <Fab.Popup>
                    <Fab.Popup.Item
                      data-testid="CustomPlan"
                      icon={<IconNotes />}
                      onClick={onShowCustomPlanPopup}
                      title={t('TrainingPlans.addPlanMenu.customPlan')}
                    />
                    <Fab.Popup.Item
                      data-testid="TemplateLibraryPlan"
                      icon={<IconBooks />}
                      onClick={onShowTemplateModal}
                      title={t('TrainingPlans.addPlanMenu.templatePlan')}
                    />
                  </Fab.Popup>
                </Fab>
              )}
              {!!filteredAndSortedPlans?.length &&
                !isFetching &&
                !isFetchingOperators && (
                  <>
                    {filteredAndSortedPlans.map((plan, idx) => (
                      <BuildPlansPlanCard
                        data-testid="TeamPlanCard"
                        key={idx}
                        onAssignTeamMembers={(members, dueDate) =>
                          onAssignTeamMembers(members, dueDate)
                        }
                        onDeletePlan={() =>
                          onDeletePlan(plan.id, getNameFromLanguage(plan.name))
                        }
                        onDuplicatePlan={(planId, operatorId, name) =>
                          onDuplicatePlan(planId, operatorId, name)
                        }
                        onRenamePlan={newName => onRenamePlan(newName, plan)}
                        onSaveTranslations={updatedTranslations =>
                          onSaveTranslations(updatedTranslations)
                        }
                        plan={plan}
                      />
                    ))}
                    <LoadMorePaginator
                      onClick={() => dispatch(loadMorePlans())}
                      showing={showing}
                      showingText={t('TrainingPlans.showingXOfYPlans', {
                        showing,
                        total,
                      })}
                      total={total}
                    />
                  </>
                )}
            </PlanCardsList>
          </PlanCardsContainer>
        </PlanCardList>
        {!!operators && (
          <AddPlanWithOperators
            cancelHandler={() => setShowCustomPlan(false)}
            nonOperatorLocations={locationsWithAtLeastLeader}
            open={showCustomPlan}
            operators={operatorsWhereUserIsLeader}
            setOpen={setShowCustomPlan}
            submitHandler={({ assignedOperator, name }) => {
              onAddPlan(name, assignedOperator);
              setShowCustomPlan(false);
            }}
          />
        )}
        {!!templates?.length && (
          <>
            <TemplateModal
              isOpen={showTemplateModal}
              onClose={() => setShowTemplateModal(false)}
              onCopy={onShowCopyPlan}
              onPreviewTemplateClick={handlePreviewPlan}
              templates={templates}
            />
            <PreviewTemplateModal
              isOpen={showPreview}
              onClose={() => setShowPreview(false)}
              onCopy={onShowCopyPlan}
              onPreviewTemplateClick={handlePreviewPlan}
              onTemplateBackButtonClick={handleTemplateBackButton}
              template={previewTemplate}
            />
          </>
        )}
        {!isFetchingOperators && (
          <CopyTemplatePopup
            isOpen={showCopyTemplatePopup}
            locations={locationsWithAtLeastTrainer}
            operators={operatorsWhereUserIsLeader}
            planName={
              getNameFromLanguage(
                templates?.find(template => template.id === copiedTemplateId)
                  ?.name,
              ) ?? ''
            }
            reopenTemplates={() => setShowTemplateModal(true)}
            setOpen={setShowCopyTemplatePopup}
            submitHandler={onCopyPlan}
          />
        )}
      </StyledContent>

      <ConfirmationModal
        bodyText={t('InvalidPlanName.paragraphText')}
        headerText={t('InvalidPlanName.errorHeader')}
        isError={true}
        isOpen={!!showInvalidNameError}
        onClose={() => setShowInvalidNameError(false)}
        primaryButtonHandler={() => setShowInvalidNameError(false)}
        primaryButtonText={t('Button.close')}
      />
    </>
  );
};

const PlanCardList = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  position: relative;
  flex-grow: 1;
  max-width: 100%;
`;

const PlanCardsContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  overflow: hidden;
`;

const PlanCardsList = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  max-width: 100%;
  gap: 8px;
`;
const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  max-width: 100%;
`;
export default withRoles(
  BuildPlansTab,
  [Constants.USER_PERMISSIONS.LEADER, Constants.USER_PERMISSIONS.OPERATOR],
  [Constants.USER_RESTRICTIONS.GREAT_BRITAIN_USER],
);
